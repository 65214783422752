import styled from "styled-components"
import { Wrapper } from "../../components/atoms/Wrapper/Wrapper"
import Tabs from "../../components/organisms/Tabs/Tabs"
import Img from "gatsby-image"

export const StyledWrapper = styled(Wrapper)`
  ${({ theme }) => theme.mq.desktop} {
    display: grid;
    grid-template-columns: 5fr 4fr;
    column-gap: 15%;
  }
`

export const PlanWrapper = styled(Wrapper)`
  ${({ theme }) => theme.mq.desktop} {
    display: grid;
    grid-template-columns: 2fr 3fr;
    column-gap: 10%;
  }
`

export const SectionHeading = styled(Wrapper)`
  margin-bottom: 1.6rem;

  ${({ theme }) => theme.mq.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10%;
    margin-bottom: 3.2rem;
  }
`

export const StyledImage = styled(Img)`
  width: 100%;
  height: auto;
  object-fit: cover !important;
`

export const StyledTabs = styled(Tabs)`
  padding: 100px 32px;

  ${({ theme }) => theme.mq.desktop} {
    padding: 100px 0;
    width: 80%;
    margin: 0 auto;
  }
`
