import React, { useRef, useState } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import arrowLeft from "../../../images/arrow-left.svg"
import arrowRight from "../../../images/arrow-right.svg"
import Dots from "../../molecules/Dots/Dots"

const StyledWrapper = styled.div`

`

const SliderItems = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 100%);
    grid-column-gap: 10%;

    transition: ease-in-out 500ms;
`

const Slide = styled.div`
    position: relative;
`

const StyledImg = styled(Img)`
    height: 500px;
    left: 0;
    top: 0;
    object-fit: contain!important;
`

const SliderControls = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4rem;
`

const SliderButtons = styled.div`

`

const SliderControl = styled.button`
    border: none;
    background: none;
    margin-right: 2rem;

    ${({ theme }) => theme.mq.desktop } {
        margin-right: 4rem;
    }
`

const Arrow = styled.img`
    height: 15px;

    ${({ theme }) => theme.mq.desktop} {
        height: 20px;   
    }
`


const Slider = ({ className, index }) => {
    const data = useStaticQuery(query)
    const listRef = useRef(null)
    const [value, setValue] = useState(0)

    const images = [
        {image1: data.kawiarnia1.childImageSharp.fluid, image2: data.kawiarnia2.childImageSharp.fluid, image3: data.kawiarnia3.childImageSharp.fluid},
        {image1: data.hol1.childImageSharp.fluid, image2: data.hol2.childImageSharp.fluid, image3: data.hol3.childImageSharp.fluid},
        {image1: data.recepcja1.childImageSharp.fluid, image2: data.recepcja2.childImageSharp.fluid, image3: data.recepcja3.childImageSharp.fluid}
    ]

    const handlePrevSlide = () => {
        listRef.current.style.transform = value === 1 ? `translateX(0%)` : `translateX(-${value - 1}10%)`
        if (value > 0) {
            setValue(value - 1)
        } 
    }

    const handleNextSlide = () => {
        if (value < 2) {
            listRef.current.style.transform = value === 1 ? `translateX(-${value + 1}20%)` : `translateX(-${value + 1}10%)`
            setValue(value + 1) 
        }
    }

    return (
        <StyledWrapper className={className}>
            <SliderItems ref={listRef}>
                    {/* <Slide value={value} index={0}><StyledImg fluid={images[1].image1} /></Slide>
                    <Slide value={value} index={1}><StyledImg fluid={data.kawiarnia2.childImageSharp.fluid} /></Slide>
                    <Slide value={value} index={2}><StyledImg fluid={data.kawiarnia3.childImageSharp.fluid} /></Slide> */}
                    {/* {images.map((item, index) => (
                        <Slide value={value} index={index}><StyledImg fluid={item[0].image1} /></Slide>
                    ))} */}

                    {index === 0 ? <><Slide value={value} index={0}><StyledImg fluid={images[0].image1} /></Slide>
                    <Slide value={value} index={1}><StyledImg fluid={images[0].image2} /></Slide>
                    <Slide value={value} index={2}><StyledImg fluid={images[0].image3} /></Slide> </>
                    : index === 1 ? <>
                    <Slide value={value} index={0}><StyledImg fluid={images[1].image1} /></Slide>
                    <Slide value={value} index={1}><StyledImg fluid={images[1].image2} /></Slide>
                    <Slide value={value} index={2}><StyledImg fluid={images[1].image3} /></Slide> </>
                    : <>
                    <Slide value={value} index={0}><StyledImg fluid={images[2].image1} /></Slide>
                    <Slide value={value} index={1}><StyledImg fluid={images[2].image2} /></Slide>
                    <Slide value={value} index={2}><StyledImg fluid={images[2].image3} /></Slide> </>
                    }
            </SliderItems>
            <SliderControls>
                <SliderButtons>
                    <SliderControl onClick={handlePrevSlide}><Arrow src={arrowLeft} /></SliderControl>
                    <SliderControl onClick={handleNextSlide}><Arrow src={arrowRight} /></SliderControl>
                </SliderButtons>
                <Dots value={value}/>
            </SliderControls>
        </StyledWrapper>
    )
}

export const query = graphql`
{
    kawiarnia1: file(name:{ eq: "kawiarnia-inspiracja-1"}) {
      childImageSharp {
        fluid (maxHeight: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    kawiarnia2: file(name:{eq: "kawiarnia-inspiracja-3"}){
      childImageSharp{
        fluid (maxHeight: 600, quality: 100){
            ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    kawiarnia3: file(name:{eq: "kawiarnia-inspiracja-4"}){
        childImageSharp{
          fluid (maxHeight: 600, quality: 100){
              ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
    hol1: file(name:{eq: "hol-inspiracja-1"}){
        childImageSharp{
          fluid (maxHeight: 600, quality: 100){
              ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
    hol2: file(name:{eq: "hol-inspiracja-2"}){
        childImageSharp{
          fluid (maxHeight: 600, quality: 100){
              ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
    hol3: file(name:{eq: "hol-inspiracja-3"}){
        childImageSharp{
          fluid (maxHeight: 600, quality: 100){
              ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
    recepcja1: file(name:{eq: "recepcja-inspiracja-1"}){
        childImageSharp{
          fluid (maxHeight: 600, quality: 100){
              ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
    recepcja2: file(name:{eq: "recepcja-inspiracja-2"}){
        childImageSharp{
          fluid (maxHeight: 600, quality: 100){
              ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
    recepcja3: file(name:{eq: "recepcja-inspiracja-3"}){
        childImageSharp{
          fluid (maxHeight: 600, quality: 100){
              ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
}
`

export default Slider