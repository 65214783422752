import React, { useState } from "react"
import styled from "styled-components"

import { Wrapper } from "../../components/atoms/Wrapper/Wrapper"
import Paragraph from "../../components/atoms/Paragraph/Paragraph"
import Tab from "../../components/atoms/Tab/Tab"
import Slider from "../../components/organisms/Slider/Slider"
import { useStaticQuery, graphql } from "gatsby"

const StyledSection = styled.section`
  padding: 100px 0;
  background-color: ${({ theme }) => theme.color.blue};
  color: white;
`

const StyledWrapper = styled(Wrapper)`
  display: grid;
  /* grid-template-columns: 2fr 3fr; */
  column-gap: 10%;
`

const StyledHeading = styled.h2`
  font-size: 2.4rem;
  font-family: ${({ theme }) => theme.font.family.greta};
  margin-top: 0.8rem;

  ${({ theme }) => theme.mq.desktop} {
    font-size: 3.2rem;
  }

  ${({ theme }) => theme.mq.widescreen} {
    font-size: 4rem;
  }

  ${({ theme }) => theme.mq.fullhd} {
    font-size: ${({ theme }) => theme.font.size.xl};
  }
`

const StyledParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.color.white};
  opacity: 0.7;
  margin-top: 4rem;

  ${({ theme }) => theme.mq.desktop} {
    margin-top: 0;
  }
`

const TabList = styled.ul`
  display: flex;
  list-style-type: none;
  margin-top: 4.8rem;

  ${({ theme }) => theme.mq.desktop} {
    margin-top: 0;
  }
`

const StyledTab = styled(Tab)`
  color: ${({ theme }) => theme.color.white};
  margin: 0;
  padding: 10px 0;
  width: calc(100% / 3);
  border-bottom: 1px solid ${({ theme }) => theme.color.white};
  opacity: ${({ isActive }) => (isActive ? "1" : "0.4")};

  &:hover {
    opacity: 1;
  }
`

const TabPanel = styled(Slider)`
  visibility: ${({ value, index }) => (value !== index ? "hidden" : "initial")};
  display: ${({ value, index }) => (value !== index ? "none" : "grid")};
  clip-path: inset(0px -30% 0px 0px);

  grid-row: 1;

  ${({ theme }) => theme.mq.desktop} {
    grid-row: initial;
  }
`

const Content = styled.div`
  display: grid;

  ${({ theme }) => theme.mq.desktop} {
    grid-template-columns: 2fr 3fr;
    grid-column-gap: 10%;
  }
`

const SectionHeader = styled.div`
  display: grid;
  margin-bottom: 3rem;
  align-items: center;

  ${({ theme }) => theme.mq.desktop} {
    margin-bottom: 8rem;
    grid-template-columns: 2fr 3fr;
    grid-column-gap: 10%;
  }
`

const tabList = [
  { index: 0, title: "Hol" },
  { index: 1, title: "Recepcja" },
  { index: 2, title: "Kawiarnia" },
]

const InteriorInspirations = ({ className }) => {
  const data = useStaticQuery(query)
  const [value, setValue] = useState(0)

  const images = [{ image: data.level0.childImageSharp.fluid }]

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <StyledSection className={className}>
      <StyledWrapper>
        <SectionHeader>
          <StyledHeading>Aranżacja wnętrz</StyledHeading>
          <TabList>
            {tabList.map(tab => (
              <StyledTab
                onClick={e => handleChange(e, tab.index)}
                index={tab.index}
                isActive={value === tab.index}
              >
                {tab.title}
              </StyledTab>
            ))}
          </TabList>
        </SectionHeader>
        <Content>
          <StyledParagraph>
            Wystrój hotelowych wnętrz stanowić będą przestrzenie, pozwalające
            poczuć otulającą moc natury. Miękkie i wygodne materiały zostaną
            skontrastowane z surowością naturalnego kamienia i złagodzone ciepłą
            barwą drewna. Wnętrze powstającego hotelu będzie emanowało luksusem
            przeplatanym naturalnością materiałów. Wyjątkowa aranżacja
            przestrzeni zostanie wyeksponowana dziełami sztuki stworzonymi przez
            miejscowych artystów, którzy raz zapuściwszy się w te okolice,
            zatracili się bez reszty w tej pięknej choć surowej krainie.
          </StyledParagraph>
          <TabPanel images={images} value={value} index={0} />
          <TabPanel value={value} index={1} />
          <TabPanel value={value} index={2} />
        </Content>
      </StyledWrapper>
    </StyledSection>
  )
}

export const query = graphql`
  {
    level0: file(name: { eq: "level-0" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    level1: file(name: { eq: "level-1" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    level2: file(name: { eq: "level-2" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    level3: file(name: { eq: "level-3" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default InteriorInspirations
