import React from "react"
import styled from "styled-components"

const StyledTab = styled.li`
    text-transform: uppercase;
    border: none;
    background: none;
    color: ${({ theme }) => theme.color.grey};
    cursor: pointer;
    /* letter-spacing: 1.6px; */
    font-size: 1.4rem;
    font-weight: bold;
    width: calc(100%/2);
    text-align: center;
    margin-bottom: 3.2rem;

    &:first-child {
        margin-left: 0;
        padding-left: 0;
    }

    ${({ theme }) => theme.mq.desktop} {
        width: calc(100%/2);
        padding: 0 10px;
        text-align: left;
    }

    ${({ theme }) => theme.mq.widescreen} {
        padding: 0 15px;
    }
`

const Tab = ({ children, ...props}) => (
    <StyledTab {...props}>{children}</StyledTab>
)

export default Tab