import React from "react"
import Img from "gatsby-image"
import { Section } from "../../components/atoms/Section/Section"
import MainTemplate from "../MainTemplate/MainTemplate"
import InteriorInspirations from "../InteriorInspirationsTemplate/InteriorInspirationsTemplate"
import TextStack from "../../components/molecules/TextStack/TextStack"
import Paragraph from "../../components/atoms/Paragraph/Paragraph"

import NumbersBox from "../../components/molecules/NumbersBox/NumbersBox"
import Footer from "../../components/organisms/Footer/Footer"
import Hero from "../../components/organisms/Hero/Hero"

import {
  StyledWrapper,
  StyledImage,
  StyledTabs,
  SectionHeading,
  PlanWrapper,
} from "./AboutInvestmentTemplate.styles"

const AboutInvestmentTemplate = ({ heroImage, sections }) => (
  <MainTemplate>
    <Hero title="O inwestycji" images={heroImage} subpage />
    <Section>
      <StyledWrapper>
        <div>
          <TextStack
            span={sections[0].name}
            title={sections[0].title}
            paragraph={sections[0].text}
          />
          <NumbersBox />
        </div>
        <Img fluid={sections[0].image} />
      </StyledWrapper>
    </Section>
    <Section>
      <SectionHeading>
        <TextStack span={sections[1].name} title={sections[1].title} />
        <Paragraph>{sections[1].text}</Paragraph>
      </SectionHeading>
      <StyledImage fluid={sections[1].image} />
    </Section>
    <Section>
      <PlanWrapper>
        <TextStack
          span={sections[2].name}
          title={sections[2].title}
          paragraph={sections[2].text}
        />
        <Img fluid={sections[2].image} />
      </PlanWrapper>
    </Section>
    <Section>
      <StyledTabs />
    </Section>
    <InteriorInspirations />
    <Section>
      <StyledWrapper>
        <TextStack title={sections[3].title} paragraph={sections[3].text} />
        <Img fluid={sections[3].image} />
      </StyledWrapper>
    </Section>
    <Footer />
  </MainTemplate>
)

export default AboutInvestmentTemplate
