import React from "react"
import styled from "styled-components"

const StyledDots = styled.div`
    display: flex;
    align-items: center;
`

const Dot = styled.span`
    padding: ${({ isActive }) => isActive ? "6px" : "4px"};
    background: white;
    border-radius: 50%;
    margin-right: 2.4rem;
    opacity: ${({ isActive }) => isActive ? "1" : "0.4"};
`

const Dots = ({ value }) => (
    <StyledDots>
        <Dot value={value} index={0} isActive={value === 0}></Dot>
        <Dot value={value} index={1} isActive={value === 1}></Dot>
        <Dot value={value} index={2} isActive={value === 2}></Dot>
    </StyledDots>
)

export default Dots