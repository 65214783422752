import React, { useState } from "react"
import styled from "styled-components"
import Tab from "../../atoms/Tab/Tab"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Paragraph from "../../atoms/Paragraph/Paragraph"

const StyledHeading = styled.h2`
    font-size: 2.4rem;
    font-family: ${({theme}) => theme.font.family.greta};
    margin-top: 0.8rem;

    ${({theme}) => theme.mq.desktop} {
        font-size: 3.2rem;
    }

    ${({ theme }) => theme.mq.widescreen} {
        font-size: 4rem;
    }

    ${({ theme }) => theme.mq.fullhd} {
        font-size: ${({theme}) => theme.font.size.xl}
    }
`

const StyledWrapper = styled.div`
    padding: 100px 0;
`

const StyledTabs = styled.div`
    display: grid;

    ${({ theme }) => theme.mq.desktop} {
        grid-template-columns: 3fr 2fr;
    
        column-gap: 10%;
    }
`

const TabList = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    list-style-type: none;
    margin: 4.8rem 0 2.4rem 0;

    ${({ theme }) => theme.mq.desktop} {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-items: initial;
        margin: 8rem 0 6.4rem 0;
    }    
`

const TabPanelsContainer = styled.div`
    grid-row: 2;

    ${({ theme }) => theme.mq.desktop} {
        grid-row: initial;
    }
`

const TabPanel = styled.div`
    visibility: ${({ value, index }) => value !== index ? "hidden" : "initial"};
    display: ${({ value, index }) => value !== index ? "none" : "block"};
`

const StyledParagraph = styled(Paragraph)`
    display: none;

    ${({ theme }) => theme.mq.desktop} {
        display: block;
    }
`

const StyledTab = styled(Tab)`
    opacity: ${({ isActive }) => isActive ? "1" : "0.6"};
    width: 100%;
    padding: 0;

    &:hover {
        opacity: 1;
    }
`

const tabList = [
    {index: 0, title: "Poziom 0"},
    {index: 1, title: "Poziom 1"},
    {index: 2, title: "Poziom 2"},
    {index: 3, title: "Poziom 3"},
]

const Tabs = ({ className }) => {
    const data = useStaticQuery(query)
    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    
    return (
        <StyledWrapper className={className}>
            <StyledTabs>
                <TabPanelsContainer>
                    <TabPanel value={value} index={0}><Img fluid={data.level0.childImageSharp.fluid} /></TabPanel>
                    <TabPanel value={value} index={1}><Img fluid={data.level1.childImageSharp.fluid} /></TabPanel>
                    <TabPanel value={value} index={2}><Img fluid={data.level2.childImageSharp.fluid} /></TabPanel>
                    <TabPanel value={value} index={3}><Img fluid={data.level3.childImageSharp.fluid} /></TabPanel>
                </TabPanelsContainer>
                <div>
                    <StyledHeading>Plany i <br />rzuty poziomów hotelu</StyledHeading>
                    <TabList>
                    {tabList.map(tab => (
                        <StyledTab onClick={e => handleChange(e, tab.index)} index={tab.index} isActive={value === tab.index}>{tab.title}</StyledTab>
                    ))}
                    </TabList>
                    <StyledParagraph> 
                    Rozważamy kilka wariantów współpracy z potencjalnym inwestorem. Aktualnie jesteśmy na etapie oceny potencjału terenu oraz jego wstępnej wizualizacji we współpracy z architektami. Potencjał terenu skupionego w bezpośrednim sąsiedztwie funkcjonującego z wysokim obłożeniem od ponad 2,5 roku hotelu Villa Collis zapewnia możliwości rozbudowy obiektu o dodatkowe 118 pokoi po ok. 35 m2, w tym 10 przestronnych apartamentów po ok. 60 m2.
                    </StyledParagraph>
                </div>
            </StyledTabs>
        </StyledWrapper>
    )
}

export const query = graphql`
{
    level0:file(name:{ eq: "level-0"}) {
      childImageSharp {
        fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    level1: file(name:{eq: "level-1"}){
      childImageSharp{
        fluid (maxWidth: 1920, quality: 100){
            ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    level2: file(name:{eq: "level-2"}){
        childImageSharp{
          fluid (maxWidth: 1920, quality: 100){
              ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
    level3: file(name:{eq: "level-3"}){
        childImageSharp{
          fluid (maxWidth: 1200, quality: 100){
              ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
}
`

export default Tabs