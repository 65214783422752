import React from "react"
import { graphql } from "gatsby"
import AboutInvestmentTemplate from "../templates/AboutInvestmentTemplate/AboutInvestmentTemplate"

const AboutInvestment = ({ data }) => {
  const heroSources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  const sections = [
    {
      name: "o inwestycji",
      title:
        "Luksusowy, pięciogwiazdkowy hotel posiadający m.in: część restauracyjno-konferencyjną, SPA, basen oraz strefę rekreacji dla najmłodszych, to miejsce dla najbardziej wymagających Gości.",
      text:
        "Położony w samym sercu Bieszczad, w pobliżu Jeziora Solińskiego stwarza doskonałe warunki do wypoczynku z maksymalnym wykorzystaniem atutów lokalizacji i walorów turystycznych okolic.  Bliskość natury, restauracja serwująca wykwintne dania na bazie lokalnych produktów, kompleksowa strefa spa and wellness – wszystko to zapewni Gościom wypoczynek na najwyższym poziomie.",
      image: data.oInwestycji.childImageSharp.fluid,
    },
    {
      name: "Wizja rozbudowy",
      title: "Obiekt planowany, wizja rozbudowy, potencjał terenu",
      text:
        "Funkcjonująca Villa Collis z nowym hotelem będzie połączona podziemnym przejściem i zostanie przeznaczona na strefę spa, wellness, fitness, siłownię oraz gabinety masażu. W nowo powstałym hotelu istniejąca infastruktura będzie stanowiła strefę wypoczynku, a okalający ją atrakcyjnie zaaranżowany ogród będzie miejscem spacerów i relaksu na łonie natury. Dodatkowymi atutami obiektu będzie przeszklona altana, grill, ognisko, miejsce na plenerowe koncerty i przyjęcia oraz lądowisko dla śmigłowców.",
      image: data.mapa.childImageSharp.fluid,
    },
    {
      name: "Plan zagospodarowania",
      title: "Plan zagospodarowania przestrzennego",
      text:
        "Rozważamy kilka wariantów współpracy z potencjalnym inwestorem. Aktualnie jesteśmy na etapie oceny potencjału terenu oraz jego wstępnej wizualizacji we współpracy z architektami. Potencjał terenu skupionego w bezpośrednim sąsiedztwie funkcjonującego z wysokim obłożeniem od ponad 2,5 roku hotelu Villa Collis zapewnia możliwości rozbudowy obiektu o dodatkowe 118 pokoi po ok. 35 m2, w tym 10 przestronnych apartamentów po ok. 60 m2.",
      image: data.plan.childImageSharp.fluid,
    },
    {
      title: "Uzasadnienie inwestycji",
      text:
        "Wieloletnia działalność Villa Collis przy pełnym obłożeniu i zachowaniu wysokich cen gwarantuje pewność, że realizowana inwestycja jest odpowiedzią na potrzeby rynku i posiada wszelkie znamiona powodzenia. Doskonała lokalizacja w samym sercu Bieszczad, dostęp do jeziora i gór oraz możliwość zagospodarowania dużego terenu na liczne atrakcje stanowią determinanty sukcesu projektu inwestycyjnego. O stale rosnącym zainteresowaniu odpoczynkiem na łonie natury w Bieszczadach, świadczy ogromna ilość zapytań o możliwość pobytu liczniejszych grup, których istniejący obiekt, z uwagi na ograniczoną infrastrukturę nie jest w stanie zrealizować. Realizacja inwestycji pozwoli również odpowiedzieć na potrzeby klienta biznesowego, umożliwi bowiem organizowanie konferencji, szkoleń i innych wydarzeń biznesowych, na które obecnie jest duże zapotrzebowanie. W malowniczym regionie Bieszczad nie ma rozbudowanego obiektu, o tak wysokim standardzie, dzięki czemu powstający hotel byłby bezkonkurencyjny.",
      image: data.inwestycjazgory.childImageSharp.fluid,
    },
  ]

  return <AboutInvestmentTemplate heroImage={heroSources} sections={sections} />
}

export const query = graphql`
  {
    mobileImage: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(name: { eq: "hero-image-desktop" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    oInwestycji: file(name: { eq: "about-investment" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    plan: file(name: { eq: "plan-zagospodarowania" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    inwestycjazgory: file(name: { eq: "inwestycja-z-gory" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    mapa: file(name: { eq: "mapa" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default AboutInvestment
